import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { ENV } from 'src/providers/environment.provider';
import { AppComponent } from './app.component';
import { ButtonComponent, CardComponent, DialogComponent, InputComponent, LoadingComponent, PickListComponent, ToastComponent } from 'mis-component-library';
import { AppRoutingModule, DEV_LOGIN_ROUTE_CONFIG } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { NotFoundPageComponent } from './framework/pages/not-found/not-found-page.component';
import { ApiInterceptorService } from './services/api-interceptor/api-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { LoginModule, LoginService } from 'login';
import { environment } from 'src/environments/environment';
import { ConfirmDialogComponent } from './framework/components/dialog-components/confirm-dialog/confirm-dialog.component';
import { PermissionsService } from './services/permissions/permissions.service';
import { DateFnsAdapter, MAT_DATE_FNS_FORMATS, MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { enGB } from 'date-fns/locale';

function initializeApp(permissionsService: PermissionsService, loginService: LoginService): () => Promise<void> {
  return async (): Promise<void> => {
    // If user is not logged in, skip permission check. They will be redirect to login during routing
    const email = await loginService.getUserEmail()
    if (email) { await permissionsService.getUserPermissions(); }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
  ],
  imports: [
    ConfirmDialogComponent,
    InputComponent,
    ButtonComponent,
    CardComponent,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    DialogComponent,
    FormsModule,
    HttpClientModule,
    LoadingComponent,
    LoginModule.forRoot({
      licenseServerUrl: environment.licenseServerUrl,
      // If the environment does not specifiy the login host, this register the login page components to be hosted itself
      pathConfig: !environment.loginHost ? DEV_LOGIN_ROUTE_CONFIG : undefined
    }),
    PickListComponent,
    ToastComponent,
    MatDateFnsModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpBackend]
      }
    })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true },
    { provide: ENV, useValue: environment },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { floatLabel: 'always', appearance: "outline" } },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [PermissionsService, LoginService], multi: true },
    { provide: MAT_DATE_LOCALE, useValue: enGB },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// AoT requires an exported function for factories
function HttpLoaderFactory(handler: HttpBackend): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(handler, [
    { prefix: './assets/i18n/', suffix: '.json' },
    { prefix: './assets/i18n/framework/', suffix: '.json' },
    { prefix: './assets/i18n/modules/home/', suffix: '.json' },
    { prefix: './assets/i18n/modules/purchasing/', suffix: '.json' },
    { prefix: './assets/i18n/modules/sales/', suffix: '.json' },
    { prefix: './assets/i18n/modules/assets/', suffix: '.json' },
    { prefix: './assets/i18n/modules/consolidations-revaluations/', suffix: '.json' },
    { prefix: './assets/i18n/modules/equity/', suffix: '.json' },
    { prefix: './assets/i18n/modules/journals/', suffix: '.json' },
    { prefix: './assets/i18n/modules/tax/', suffix: '.json' },
    { prefix: './assets/i18n/modules/forecasting-budgeting/', suffix: '.json' },
    { prefix: './assets/i18n/modules/treasury/', suffix: '.json' },
    { prefix: './assets/i18n/modules/configuration/', suffix: '.json' },
    { prefix: './assets/i18n/modules/shared/', suffix: '.json' },
    { prefix: './assets/i18n/pages/not-found/', suffix: '.json' }
  ]);
}