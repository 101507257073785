import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, switchMap } from 'rxjs';
import { TenantService } from '../tenant/tenant.service';
import { SKIP_INTERCEPTOR, SKIP_ORGANISATION } from 'src/types/custom-headers';

const LOCAL_STORAGE_ORGANISATION_ID_NAME = 'organisationId';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  
  constructor(private readonly tenantService: TenantService) {  }

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(req.headers.has(SKIP_INTERCEPTOR)) { 
      const apiRequest = req.clone({ headers: req.headers.delete(SKIP_INTERCEPTOR) });
      return next.handle(apiRequest);
    }

    if (req.headers.has(SKIP_ORGANISATION)) {
      req = req.clone({ headers: req.headers.delete(SKIP_ORGANISATION)});
    }
    else {
      const organisationId = localStorage.getItem(LOCAL_STORAGE_ORGANISATION_ID_NAME);

      if (organisationId) {
        req = req.clone({ headers: req.headers.append('organisationId', organisationId.toString())});
      }
    }

    return from(this.tenantService.getAuthorizationHeaders()).pipe(
      switchMap(authHeaders => {
        let combinedHeaders = new HttpHeaders();
        const existingHeaders = req.headers;

        authHeaders.keys().forEach(key => combinedHeaders = combinedHeaders.set(key, authHeaders.get(key) ?? ''));
        existingHeaders.keys().forEach(key => combinedHeaders = combinedHeaders.set(key, existingHeaders.get(key) ?? ''));

        const apiRequest = req.clone({ headers: combinedHeaders });
        return next.handle(apiRequest);
      })
    );
  }
}