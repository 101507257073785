import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundPageComponent } from './framework/pages/not-found/not-found-page.component';
import { AuthGuard } from './guards/auth.guard';
import { environment } from 'src/environments/environment';
import { LoginRoutingModule } from 'login';
import { LoginPathConfig } from 'login/lib/types/login-config';

export const DEV_LOGIN_ROUTE_CONFIG: LoginPathConfig = {
  loginPath: 'login',
  newPasswordPath: 'login/new-password'
};

const routes: Routes = [{
  path: '',
  pathMatch: 'full',
  redirectTo: 'app'
}, {
    canActivate: [AuthGuard.canLoadApp],
    loadChildren: () => import('./framework/framework.module').then((m) => m.FrameworkModule),
    path: 'app'
  }
];

if (!environment.loginHost) {
  routes.push(...LoginRoutingModule.getRoutes(DEV_LOGIN_ROUTE_CONFIG))
}

routes.push({
  component: NotFoundPageComponent,
  path: '**',
  title: 'Active F - Page Not Found'
});

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }