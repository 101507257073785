import { Injectable } from '@angular/core';
import { TenantService } from '../tenant/tenant.service';
import { HttpClient } from '@angular/common/http';
import { UserPermissionForLegalEntity } from 'src/types/user-permission-for-legal-entity';
import { firstValueFrom } from 'rxjs';
import { SecurityTasks } from 'src/enums/securityTasks';

const LOCAL_STORAGE_ORGANISATION_ID_NAME = 'organisationId';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  public userPermissions: UserPermissionForLegalEntity[] = [];
  
  constructor(
    private readonly tenantService: TenantService,
    private readonly http: HttpClient
  ) { }

  public async getUserPermissions() : Promise<void> {
    const apiUrl = await this.tenantService.getApiUrl();
    const url = `${apiUrl}permissions`;
    this.userPermissions = await firstValueFrom(this.http.get<UserPermissionForLegalEntity[]>(url))
  }

  public checkUserHasPermission(securityTaskId: SecurityTasks): boolean {
    const legalEntityId = localStorage.getItem(LOCAL_STORAGE_ORGANISATION_ID_NAME);
    if (!legalEntityId) { return false; }
    const permissionsForThisLegalEntity = this.userPermissions.find((permissionByLegalEntity: UserPermissionForLegalEntity ) => permissionByLegalEntity.legalEntityId == parseInt(legalEntityId));
    if (!permissionsForThisLegalEntity) { return false; }
    return permissionsForThisLegalEntity.permissions.includes(securityTaskId)
  }
}
