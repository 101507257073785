import { Environment } from "src/types/environment";

export const environment: Environment = {
  production: false,
  appShortName: 'ActiveF',
  licenseServerUrl: 'https://iyj3ipjim2.execute-api.eu-west-2.amazonaws.com/Prod/',
  staticDataExpireDurationInMS: 3600000,
  loginHost: "https://dev.cloud.incline-it.com",
  cognitoConfig: {
    userPoolId: "eu-west-2_3J9uwiJjm",
    userPoolClientId: "52tjdpv066unkb1lhbaa2df649",
    loginWith: {
      email: true,
      oauth: {
        domain: "dev-cloud.auth.eu-west-2.amazoncognito.com",
        scopes: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
        redirectSignIn: ["https://dev.activef.cloud.incline-it.com"],
        redirectSignOut: ["https://dev.activef.cloud.incline-it.com"],
        responseType: 'code'
      }
    }
  }
};