import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import { TenantService } from "../tenant/tenant.service";
import { Subject, firstValueFrom, takeUntil, timer } from "rxjs";
import { StaticData } from "src/types/static-data";
import { StaticDataResponse } from "src/types/static-data-response";
import { VatCodeOld } from "src/types/vat-code";
import { FixedAssetStatus } from "src/types/fixed-asset/fixed-asset-status";
import { FixedAssetCondition } from "src/types/fixed-asset/fixed-asset-condition";
import { FixedAssetDisposalType } from "src/types/fixed-asset/fixed-asset-disposal-type";
import { FixedAssetDisposalReason } from "src/types/fixed-asset/fixed-asset-disposal-reason";
import { Environment } from "src/types/environment";
import { ENV } from "src/providers/environment.provider";
import { Country } from "src/types/country";
import { Currency } from "src/types/currency";
import { GeneralStatus } from "src/types/general-status";

@Injectable({
  providedIn: 'root'
})
export class StaticDataService implements OnDestroy {
  private staticDataPromise?: Promise<StaticData>;
  private expired = true;

  private readonly unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(ENV) private environment: Environment,
    private tenantService: TenantService,
    private http: HttpClient
  ) { }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Preloads static data
   */
  public loadStaticData(): void {
    if (!this.expired) { return; }
    this.staticDataPromise = this.getStaticData();
  }

  private async getStaticData(): Promise<StaticData> {
    this.expired = false;
    try {
      const apiUrl = await this.tenantService.getApiUrl();
      const url = apiUrl + 'Objects?Source=Config';
      const headers = await this.tenantService.getAuthorizationHeaders();
      const response = await firstValueFrom(this.http.get<StaticDataResponse>(url, { headers: headers }));

      this.unsubscribe$.next();
      timer(this.environment.staticDataExpireDurationInMS).pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        console.log('StaticData has expired.');
        this.expired = true;
      });

      return response;
    } catch (error) {
      this.expired = true;
      throw error;
    }
  }

  /**
   * Gets the currencies
   * @returns the list of currencies
   */
  public async getCurrencies(): Promise<Currency[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.currencies;
  }

  /**
   * Gets the VAT codes
   * @returns the list of VAT codes
   */
  public async getVatCodes(): Promise<VatCodeOld[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.vatCodes;
  }

  /**
   * Gets the Fixed Asset Status
   * @returns an array of the FixedAssetStatus
   */
  public async getFixedAssetStatus(): Promise<FixedAssetStatus[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.fixedAssetStatus;
  }

  /**
   * Gets the Fixed Asset Condition
   * @returns an array of FixedAssetCondition
   */
  public async getFixedAssetConditions(): Promise<FixedAssetCondition[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.fixedAssetConditions;
  }

  /**
   * Gets the Fixed Asset Disposal Type
   * @returns an array of FixedAssetDisposalType
   */
  public async getFixedAssetDisposalTypes(): Promise<FixedAssetDisposalType[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.fixedAssetDisposalTypes;
  }

  /**
   * Gets the Fixed Asset Disposal Reason
   * @returns an array of FixedAssetDisposalType
   */
  public async getFixedAssetDisposalReasons(): Promise<FixedAssetDisposalReason[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.fixedAssetDisposalReasons;
  }

  /**
   * Gets the countries
   * @returns the list of countries
   */
  public async getCountries(): Promise<Country[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.countries;
  }

  /**
   * Gets the general status list
   * @returns the list of general status
   */
  public async getGeneralStatus(): Promise<GeneralStatus[]> {
    this.loadStaticData();
    if (!this.staticDataPromise) { throw new Error('Static data promise must not be null'); }

    const staticData = await this.staticDataPromise;
    return staticData.generalStatuses;
  }
}